<template>
    <div>
        <div class="welcome" v-if="welcome" >
            <div class="dx_image"><img src="../assets/images/dx_logo.png"></div>
            <div class="bj"><img src="../assets/images/t1.png"></div>
            <div class="wz">TD韦氏成人智力测验WAIS-RC V1.0</div>
            <div class="flex hy" @click="tiaozhuan">
                <div class="hyjt"><img src="../assets/images/t2.gif"></div>
            </div>
        </div>
        <div class="login" v-if="denglu">
            <div class="exit out">
                <img  src="../assets/images/login_exit.png" alt="" @click="exit">
            </div>
            <div class="logo_box">
                <div>
                    <div class="logo">
                        <img src="../assets/images/logo.png" alt="">
                    </div>

                    <div class="login-content">
                        <p>用户登录</p>
                        <form>
                            <div class="item">
                                <img src="../assets/images/login_user.png" alt="">
                                <el-input v-model="username" type="text" placeholder="请输入用户名"></el-input>
                            </div>
                            <div class="item">
                                <img src="../assets/images/login_pwd.png" alt="">
                                <el-input v-model="password" show-password type="password" placeholder="请输入密码"></el-input>
                            </div>
                            <div class="jizhu">
                                <el-checkbox v-model="remember">记住密码</el-checkbox>
                            </div>
                            <el-button round class="login-btn" @click="login">登录</el-button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
var userAgent = navigator.userAgent.toLowerCase()
if(userAgent.indexOf('electron/')>-1) {
    console.log('桌面应用')
    var Store = window.require('electron-store')
    var storeName = new Store();
}else {
    console.log('浏览器')
}

export default {
    name    : "Login",
    data() {
        return {
            username: '',
            password: '',
            remember: false,
            welcome:true,
            denglu:false
        };
    },
    computed: {},
    watch   : {},
    methods : {
        tiaozhuan() {
            this.welcome = false
            this.denglu = true
        },
        async login() {
            // console.log('exec',window.window.getPwd())
            if (!this.username) {
                this.$message.error('请输入用户名');
                return;
            }
            if (!this.password) {
                this.$message.error('请输入密码');
                return;
            }

            await this.$axios.post('api/index/login', {username: this.username, password: this.password}).then(res => {
                if (res.data.code === 1) {
                    this.$store.commit('setToken', res.data.data.token)
                    this.$store.commit('setUserInfo', {data: res.data.data, status: true, username: this.username});

                    sessionStorage.setItem('usertype','center')
                    var userAgent = navigator.userAgent.toLowerCase()
                    if(userAgent.indexOf('electron/')>-1) {
                        if (this.remember) {
                            //记住密码
                            storeName.set("wsname",this.username);
                            storeName.set("wspassword",this.password);
                        } else {
                            storeName.delete('wsname');
                            storeName.delete('wspassword');
                        }
                    }else {
                        if (this.remember) {
                            this.$store.commit('setAccount', {username: this.username, password: this.password});
                        } else {
                            this.$store.commit('setAccount', '');
                        }
                    }

                    if(res.data.data.hukou == 0) {
                        // console.log('跳转选择城市农村')
                        this.$router.push('/hukou');
                        this.$store.commit('setHukouclick', true)
                    } else {
                       // console.log('跳转首页')
                        this.$router.push('/');
                        this.$store.commit('setHukouclick', false)
                        this.$store.commit('setHukou', res.data.data.hukou)
                    }
                }
            })
        },
        exit() {
            this.$confirm('确定退出系统吗', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                window.window.close()
            }).catch(() => {
            });
        }
    },

    mounted() {
        var userAgent = navigator.userAgent.toLowerCase()
        if(userAgent.indexOf('electron/')>-1) {
            if(storeName.has("wsname")){
                this.password = storeName.get('wspassword')
                this.username = storeName.get('wsname')
                this.remember = true
            } else  {
                this.remember = false
            }
        }else {
            if (this.$store.state.user.account) {
                let data      = this.$store.state.user.account;
                this.password = data.password;
                this.username = data.username;
                this.remember = true;
            }
        }
        this.$store.commit('setClient', false);

        if (this.$store.state.user.account) {
            let data      = this.$store.state.user.account;
            this.password = data.password;
            this.username = data.username;
            this.remember = true;
        }
    }
}
</script>
<style scoped lang="scss">
.welcome {
    width: 100vw;
    height: 100vh;
    background: url('../assets/images/tp.png') no-repeat center;
    background-size: cover;
    overflow: hidden;
    .dx_image {
        width: 400px;
        height: 100px;
        position: absolute;
        left: 100px;
        top: 10vh;
        img {
            width: 100%;
            height: 100%;
        }
    }
    .bj {
        width: 232px;
        height: 232px;
        margin: 0 auto;
        margin-top: 23vh;
        img {
            width: 100%;
            height: 100%;
        }
    }
    .wz {
        width: 1702px;
        height: 148px;
        font-size: 100px;
        font-weight: 800;
        color: #8197FE;
        line-height: 148px;
        margin: 0 auto;
    }
    .hy {
        margin-top: 22vh;
        //margin-left: 1546px;
        cursor: pointer;
        float: right;
    }
    .hyjt {
        background: url('../assets/images/t3.png') no-repeat center;
    }
}
.login {
    width: 100vw;
    height: 100vh;
    background: url('../assets/images/loginBG.png') no-repeat center;
    background-size: cover;
}
.logo_box {
    width: 652px;
    height: 717px;
    position: absolute;
    top: 20%;
    left: 53%;
    //background: url('../assets/images/dl_bg.png') no-repeat center;
    display: flex;
    justify-content: center;
    .logo {
        margin-top: 60px;
        margin-left: 30px;
        img{
            width: 426px;
            height: 85px;
        }
    }
    .login-content {
        margin-top: 22px;
        overflow: hidden;
        width: 500px;
        height: 468px;
        background: #FFFFFF;
    }
    .login-content p {
        color: #333333;
        font-size: 30px;
        margin: 50px 0;
        text-align: center;
        font-weight: 400;
    }

    .login-content .item {
        display: flex;
        align-items: center;
        border-radius: 8px;
        border: 1px solid #8197FE;
        width: 404px;
        height: 55px;
        margin: 0 48px 30px 48px;
        padding: 0 20px;
    }

    .login-content .item img {
        width: 20px;
        height: 20px;
    }

    .login-content .item input {
        flex: 1;
        margin-left: 15px;
        font-size: 14px;
    }

    .login-content .item input::-webkit-input-placeholder {
        color: #999999;
    }

    .login-btn {
        display: block;
        background: #8197FE;
        border: none!important;
        color: #ffffff;
        font-weight: 600;
        font-size: 16px;
        border-radius: 8px !important;
        width: 380px;
        height: 54px;
        margin: 0 auto;
    }

    .jizhu {
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #989898;
        margin-bottom: 40px;
        margin-left: 60px;
    }

    .jizhu input {
        width: 14px;
        height: 14px;
        margin: 0 10px 0 0;
    }

}



.exit {
    width: 60px;
    height: 60px;
    background: #FFFFFF;
    border-radius: 50%;
    position: fixed;
    top: 8%;
    right: 8%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        display: block;
        width: 26px;
        height: 28px;
    }
}

::v-deep .el-input__inner {
    border: none;
    background-color: transparent;
}
::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #8197FE;
}
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
    color: #8197FE;
    background-color: #8197FE;
    border-color: #8197FE;
}
</style>
